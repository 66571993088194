import React from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";
import PriceField from "./PriceField";

const BookFormRightPrice = ({ label, name, value, isPackage }) => {
  const { t } = useTranslation();

  return (
    <div className="book-form-right-field-service">
      <strong className="book-form-right-label-service">{`${t(`${label}`)} :`}</strong>{" "}
      <PriceField name={name} value={value} isPackage={isPackage} />
    </div>
  );
};

export default BookFormRightPrice;
