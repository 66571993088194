import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useBooking } from "../../../providers/BookProvider";
import { getBookRoutePrice } from "../utils/functions";
import { useTranslation } from "react-i18next";

const BookFormLeftFieldSelect = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, setFormData, setSelectedRoute } = useBooking();
  const [selectedOption, setSelectedOption] = useState(null);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    // Función para obtener los paquetes
    const fetchRoutes = async () => {
      try {
        const response = await fetch("https://backend.caminoperegrino.com/api/routes/");
        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status}`);
        }
        const data = await response.json(); // Parsear los datos
        const routeName = currentLang === "en" ? data[0].name_en : data[0].name;
        setSelectedRoute(data[0]);
        setSelectedOption({
          value: data[0]?.id,
          label: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ alignSelf: "center" }}>{routeName}</div>
              <div className="package-price" title="precio por persona">{` ${getBookRoutePrice(selectedPackage.id, data[0])} €`}</div>
              {/* <span>${getBookRoutePrice(selectedPackage.id, route)}</span> */}
            </div>
          ),
        });
        setFormData((prev) => ({
          ...prev,
          route: data[0]?.id,
        }));
        setRoutes(data); // Guardar los datos en el estado
      } catch (err) {
        console.error(err.message); // Capturar el error
      } finally {
        console.info(false); // Marcar la carga como completada
      }
    };

    fetchRoutes();
  }, []); // [] asegura que se ejecuta solo al montar el componente

  const getRoute = async (routeId) => {
    try {
      const response = await fetch(`https://backend.caminoperegrino.com/api/routes/${routeId}/`);
      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }
      const data = await response.json(); // Parsear los datos
      setSelectedRoute(data);
      return data;
    } catch (err) {
      console.error(err.message); // Capturar el error
    } finally {
      console.info(false); // Marcar la carga como completada
    }
  };

  // Formatear opciones para react-select
  const options = routes.map((route) => ({
    value: route.id,
    label: (
      <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
        <div style={{ alignSelf: "center" }}>{currentLang === "en" ? route.name_en : route.name}</div>
        <div className="package-price" title="precio por persona">{` ${getBookRoutePrice(selectedPackage.id, route)} €`}</div>
      </div>
    ),
    price: getBookRoutePrice(selectedPackage.id, route),
    name: route.name,
  }));

  const handleChange = (selectedValue) => {
    getRoute(selectedValue.value);
    setSelectedOption(selectedValue);
    setFormData((prev) => ({
      ...prev,
      route: selectedValue.value,
    }));
  };

  return (
    <label className="book-form-left-label">
      {t("book.form.route")}

      <Select
        options={options}
        onChange={handleChange}
        placeholder="Selecciona un producto"
        value={selectedOption}
        styles={{
          control: (base) => ({
            ...base,
            padding: "5px",
            fontSize: "16px",
            borderRadius: "4px",
          }),
          option: (base) => ({
            ...base,
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }),
        }}
      />
    </label>
  );
};

export default BookFormLeftFieldSelect;
