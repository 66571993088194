import React from "react";
import { useTranslation } from "react-i18next";

const CancelacionReembolsos = () => {
  const { t } = useTranslation();
  return (
    <div className="legal-text">
      <h1>{t("text.policy.cancel.title")}</h1>
      <p>{t("text.policy.cancel.update")} 25/11/2024</p>
      <h2>{t("text.policy.cancel.clause.h1")}</h2>
      <p>{t("text.policy.cancel.clause.description1")}</p>
      <h2>{t("text.policy.cancel.clause.h2")}</h2>
      <p>{t("text.policy.cancel.clause.description2")}</p>
      <ul>
        <li>{t("text.policy.cancel.clause.description2.conditions1")}</li>
        <li>{t("text.policy.cancel.clause.description2.conditions2")}</li>
      </ul>
      <h2>{t("text.policy.cancel.clause.h3")}</h2>
      <p>{t("text.policy.cancel.clause.description3")}</p>
    </div>
  );
};

export default CancelacionReembolsos;
