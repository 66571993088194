import React from "react";
import "../BookForm.css";
import { getBookRoutePrice } from "../utils/functions";
import { useBooking } from "../../../providers/BookProvider";
import BookFormRightField from "./BookFormRightField";
import BookFormRightPrice from "./BookFormRightFieldPrice";
import { useTranslation } from "react-i18next";

const BookFormRightFromPackage = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, selectedRoute } = useBooking();

  const routeName = currentLang === "en" ? selectedRoute?.name_en : selectedRoute?.name;
  const packageName = currentLang === "en" ? selectedPackage?.name_en : selectedPackage?.name;
  return (
    <>
      <BookFormRightField label={"book.form.package"} value={packageName || ""} />
      <BookFormRightPrice
        label={"book.form.route"}
        name={routeName || ""}
        value={getBookRoutePrice(selectedPackage.id, selectedRoute)}
        isPackage={true}
      />
    </>
  );
};

export default BookFormRightFromPackage;
