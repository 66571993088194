import React, { useState } from "react";
import "./header.css";
import logo from "../../public/logo.png";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/contactForm/ContactForm";
import ServicesInfo from "../../components/services-specs/ServicesInfo";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const currentLang = i18n.language;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [isServicesInfoOpen, setIsServicesInfoOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const toggleLanguageMenu = () => setLanguageMenuOpen(!isLanguageMenuOpen);
  const togleServicesInfo = () => setIsServicesInfoOpen(!isServicesInfoOpen);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const openForm = () => setIsFormVisible(true);
  const closeForm = () => setIsFormVisible(false);

  const toggleLanguage = () => {
    const newLang = currentLang === "en" ? "es" : "en"; // Alterna entre inglés y español
    i18n.changeLanguage(newLang);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo GaliRoutes" className="logo" />
        <h1 className="company-name first">Camino</h1>
        <h1 className="company-name second">Peregrino</h1>
      </div>
      <nav className="menu">
        <ul>
          <li>
            <div onClick={openForm}>{t("header.option.contact")}</div>
          </li>
        </ul>
        <ul>
          <li>
            <div onClick={togleServicesInfo}>{t("header.menu.option.services.info")}</div>
          </li>
        </ul>
        <ul>
          <li className="language-menu">
            <div onClick={toggleLanguageMenu}>{t("header.menu.option.change.language")}</div>
            {isLanguageMenuOpen && (
              <ul className="submenu">
                <li onClick={() => i18n.changeLanguage("es")}>{t("header.menu.option.change.language.spanish")}</li>
                <li onClick={() => i18n.changeLanguage("en")}>{t("header.menu.option.change.language.english")}</li>
              </ul>
            )}
          </li>
        </ul>
        {/* <button className="language-switcher" onClick={toggleLanguage} aria-label={t("header.language.change")}>
            {currentLang === "en" ? "es" : "en"}
          </button> */}
      </nav>
      {isFormVisible && <ContactForm closeForm={closeForm} />}
      {isServicesInfoOpen && <ServicesInfo closeServicesModal={togleServicesInfo} />}
      {/* {isMobile && (
        <>
          <div className={`hamburger ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <div onClick={openForm}>{t("header.option.contact")}</div>
              </li>
              <li>
                <div onClick={togleServicesInfo}>{t("header.menu.option.services.info")}</div>
              </li>
              <li className="language-menu">
                <button onClick={toggleLanguageMenu}>Cambiar Idioma</button>
                {isLanguageMenuOpen && (
                  <ul className="submenu">
                    <li onClick={() => i18n.changeLanguage("es")}>{t("header.menu.option.change.language.spanish")}</li>
                    <li onClick={() => i18n.changeLanguage("en")}>{t("header.menu.option.change.language.english")}</li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
        </>
      )} */}
    </header>
  );
};

export default Header;
