import React, { useState } from "react";
import "./route-info.css";
import { GiDuration } from "react-icons/gi";
import { MdNordicWalking } from "react-icons/md";
import { FaBed } from "react-icons/fa";
import { WiDaySunny } from "react-icons/wi";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import default_img from "../../public/paquete.jpg";
import { useBooking } from "../../providers/BookProvider";

const RouteInfoStage = ({ stage, index }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { selectedRoute } = useBooking();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const description = currentLang === "en" ? stage.description_en : stage.description;
  const stageName = currentLang === "en" ? stage.name_en : stage.name;
  return (
    <div className="route-info-stage-content">
      <h3 className="stage-content-title">
        {t("route.modal.info.day")} {stage.day} - {stageName}
      </h3>
      <div className="stage-container">
        <div className="stage-content-left">
          <div>
            <div className="stage-places">
              {(index !== 0 || selectedRoute?.id === 8) && (
                <div className="stage-content-aux-info" style={{ marginRight: "10px" }}>
                  <div className="aux-info-title">
                    <WiDaySunny className="route-info-icons" />
                    <div>{t("route.modal.info.awake")} </div>
                  </div>
                  <div className="aux-info-text">{stage.awake_place}</div>
                </div>
              )}
              <div className="stage-content-aux-info">
                <div className="aux-info-title">
                  <FaBed className="route-info-icons" />
                  <div>{t("route.modal.info.sleep")} </div>
                </div>
                <div className="aux-info-text">{stage.sleeping_place}</div>
              </div>
            </div>
          </div>
          {(index !== 0 || selectedRoute?.id === 8) && (
            <div>
              <div className="stage-content-aux-info">
                <div className="aux-info-title">
                  <MdNordicWalking className="route-info-icons" />
                  <div>Kms: </div>
                </div>
                <div className="aux-info-text">{stage.kms}</div>
              </div>
              <div className="stage-content-aux-info">
                <div className="aux-info-title">
                  <GiDuration className="route-info-icons" />
                  <div>{t("route.modal.info.duration")} </div>
                </div>
                <div className="aux-info-text">{stage.period}</div>
              </div>
            </div>
          )}
        </div>
        {!isMobile ? (
          <>
            <div className={`stage-content-description ${isExpanded ? "expanded" : ""}`}>{description}</div>
            <button className="toggle-button" onClick={toggleExpand} aria-expanded={isExpanded}>
              {isExpanded ? "Mostrar menos" : "Mostrar más"}
            </button>
          </>
        ) : (
          <div className="stage-content-description">{description}</div>
        )}
        <div className="stage-content-right">
          <img src={stage.url_photo || default_img} alt="Logo GaliRoutes" className="aux-info-image" />
        </div>
      </div>
    </div>
  );
};

export default RouteInfoStage;
