import React from "react";
import "./Package.css";
import { useBooking } from "../../providers/BookProvider";
import BookForm from "../book-form/BookForm";
import { getBookRoutePrice } from "../book-form/utils/functions";
import { useTranslation } from "react-i18next";

const PackageForm = ({ pckg, isForm }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, setSelectedPackage, setFormData, isBookPackageModalOpen, openPackageForm, selectedRoute } = useBooking();

  const isSelected = selectedPackage?.id === pckg.id;

  const handleSelect = () => {
    setSelectedPackage(pckg);
    setFormData((prev) => ({ ...prev, package: pckg.id }));

    !isForm && openPackageForm();
  };
  const description = currentLang === "en" ? pckg.description_en : pckg.description;
  const pckgName = currentLang === "en" ? pckg.name_en : pckg.name;
  return (
    <div className={`package-content-form ${isSelected && isForm ? "selected" : ""}`}>
      <div className="package-title-container-form">
        <div className={`package-title-form ${isSelected && isForm}`}>{pckgName}</div>
        <div className="package-price-form" title="precio por persona">{` ${getBookRoutePrice(pckg.id, selectedRoute)} €`}</div>
      </div>
      {/* <img src={logo} alt="Logo GaliRoutes" className="package-image" /> */}
      <div className={`package-description-form ${isSelected && isForm}`}>{description}</div>
      <button className="package-button" onClick={handleSelect}>
        {t("package.button.choose")}
      </button>
      {isBookPackageModalOpen && <BookForm type={"package"} />}
    </div>
  );
};

export default PackageForm;
