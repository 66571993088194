export const handleAcceptCookies = () => {
  // Enviar la aceptación al backend
  fetch("/api/cookies-consent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ consent: true }),
  })
    .then((response) => response.json())
    .then((data) => {
      window.location.reload();
    })
    .catch((error) => console.error("Error al registrar el consentimiento", error));
};

export const handleDeclineCookies = () => {
  // Enviar el rechazo al backend
  fetch("/api/cookies-consent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ consent: false }),
  })
    .then((response) => response.json())
    .then((data) => {
      window.location.reload();
    })
    .catch((error) => console.error("Error al registrar el rechazo", error));
};

// utils/functions.js
export const handleAcceptNecessaryCookies = () => {
  // Enviar el rechazo al backend
  fetch("/api/cookies-consent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ consent: true }),
  })
    .then((response) => response.json())
    .then((data) => {
      window.location.reload();
    })
    .catch((error) => console.error("Error al registrar el rechazo", error));
  document.cookie = "cookies_consent=necessary; path=/; max-age=31536000"; // Guardar por 1 año
};
