import React from "react";
import { useTranslation } from "react-i18next";

const PoliticaCookies = () => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.8" }}>
      <h1 style={{ fontSize: "2rem", marginBottom: "1rem" }}>{t("text.policy.cookies.title")}</h1>
      <p style={{ fontStyle: "italic" }}>{t("text.policy.cancel.update")} 25/11/2024</p>

      <p>
        {t("text.policy.cookies.description.p1")} <strong>CAMINO PEREGRINO</strong>
        {t("text.policy.cookies.description.p2")}
      </p>

      <h2 style={{ fontSize: "1.5rem", marginTop: "2rem" }}>{t("text.policy.cookies.clause.h1")}</h2>
      <p>{t("text.policy.cookies.clause.description1")}</p>

      <h2 style={{ fontSize: "1.5rem", marginTop: "2rem" }}>{t("text.policy.cookies.clause.h2")}</h2>
      <p>
        {t("text.policy.cookies.description.p1")} <strong>CAMINO PEREGRINO</strong>
        {t("text.policy.cookies.clause.description2")}
      </p>

      <h3 style={{ fontSize: "1.25rem", marginTop: "1.5rem" }}>{t("text.policy.cookies.clause.description2.clause1.title")}</h3>
      <p>{t("text.policy.cookies.clause.description2.clause1.description")}</p>
      <ul style={{ marginLeft: "20px" }}>
        <li>{t("text.policy.cookies.clause.description2.clause1.description.l1")}</li>
        <li>{t("text.policy.cookies.clause.description2.clause1.description.l2")}</li>
      </ul>

      <h3 style={{ fontSize: "1.25rem", marginTop: "1.5rem" }}>{t("text.policy.cookies.clause.description2.clause2.title")}</h3>
      <p>
        {t("text.policy.cookies.clause.description2.clause2.description1")} <strong>Stripe</strong>{" "}
        {t("text.policy.cookies.clause.description2.clause2.description2")}
      </p>
      <p>
        <strong>{t("text.policy.cookies.clause.description2.clause2.providers")}</strong>
      </p>
      <ul style={{ marginLeft: "20px" }}>
        <li>
          Stripe -{" "}
          <a href="https://stripe.com/es/legal/privacy-center" target="_blank" rel="noopener noreferrer">
            {t("text.policy.cookies.clause.description2.clause2.providers.link")}
          </a>
        </li>
      </ul>

      <h3 style={{ fontSize: "1.25rem", marginTop: "1.5rem" }}>{t("text.policy.cookies.clause.description2.clause3.title")}</h3>
      <p>{t("text.policy.cookies.clause.description2.clause3.description1")}</p>
      <p>
        <strong>{t("text.policy.cookies.clause.description2.clause2.providers")}</strong>
      </p>
      <ul style={{ marginLeft: "20px" }}>
        <li>
          Google Analytics -{" "}
          <a href="[Enlace a su política de cookies]" target="_blank" rel="noopener noreferrer">
            {t("text.policy.cookies.clause.description2.clause2.providers.link")}
          </a>
        </li>
      </ul>

      <h2 style={{ fontSize: "1.5rem", marginTop: "2rem" }}>{t("text.policy.cookies.clause.description2.clause4.title")}</h2>
      <p>{t("text.policy.cookies.clause.description2.clause4.description1")}</p>

      <h2 style={{ fontSize: "1.5rem", marginTop: "2rem" }}>{t("text.policy.cookies.clause.description2.clause5.title")}</h2>
      <p>{t("text.policy.cookies.clause.description2.clause5.description1")}</p>
      <ul style={{ marginLeft: "20px" }}>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">
            Google Chrome
          </a>
        </li>
        <li>
          <a
            href="https://support.mozilla.org/es/kb/activar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge
          </a>
        </li>
      </ul>

      <h2 style={{ fontSize: "1.5rem", marginTop: "2rem" }}>{t("text.policy.cookies.clause.description2.clause6.title")}</h2>
      <p>
        {t("text.policy.cookies.clause.description2.clause6.description1")}{" "}
        <a href="mailto:caminoperegrinoo@gmail.com">caminoperegrinoo@gmail.com</a>.
      </p>
    </div>
  );
};

export default PoliticaCookies;
