import React, { createContext, useState, useContext } from "react";
import "./NotificationProvider.css";
const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 5000); // Hide after 5 seconds
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}

      {/* Floating notification */}
      {notification && (
        <div className={`floating-message ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </NotificationContext.Provider>
  );
};
