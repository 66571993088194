import React from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";

const BookFormRightFieldLong = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <div className="book-form-right-field-service">
      <strong className="book-form-right-label-service">{`${t(`${label}`)} :`}</strong>{" "}
      <div className="book-form-resume-check">
        <div className="book-form-services-check-container">
          <div className="resume-service-name-field">{value || t("book.form.empty")}</div>
        </div>
      </div>
    </div>
  );
};

export default BookFormRightFieldLong;
