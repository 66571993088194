import React from "react";
import { useTranslation } from "react-i18next";

const TerminosCondiciones = () => {
  const { t } = useTranslation();
  return (
    <div className="legal-text">
      <h1>{t("text.policy.terms.title")}</h1>
      <p>{t("text.policy.cancel.update")} 25/11/2025</p>
      <h2>{t("text.policy.terms.intro.title")}</h2>
      <p>
        {t("text.policy.terms.intro.description.p1")}
        <strong>CAMINO PEREGRINO</strong>.{t("text.policy.terms.intro.description.p2")}
      </p>
      <h2>{t("text.policy.terms.clause1.title")}</h2>
      <p>{t("text.policy.terms.clause1.description")}</p>
      <h2>{t("text.policy.terms.clause2.title")}</h2>
      <p>{t("text.policy.terms.clause2.description")}</p>
      <h2>{t("text.policy.terms.clause3.title")}</h2>
      <p>{t("text.policy.terms.clause3.description")}</p>
    </div>
  );
};

export default TerminosCondiciones;
