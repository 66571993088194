import React, { useEffect, useState } from "react";
import "./services-info.css";
import { FaCheck } from "react-icons/fa6";
import logo from "../../public/paquete.jpg";
import ServicesInfoIncluded from "./ServicesInfoIncluded";
import ServicesInfoExtra from "./ServicesInfoExtra";
import { useTranslation } from "react-i18next";

const ServicesInfo = ({ closeServicesModal }) => {
  const { t } = useTranslation();
  const [basicIncludedServices, setBasicIncludedServices] = useState(null);
  const [basicExtraServices, setBasicExtraServices] = useState(null);
  const [classicIncludedServices, setClassicIncludedServices] = useState(null);
  const [classicExtraServices, setClassicExtraServices] = useState(null);

  useEffect(() => {
    fetch(`https://backend.caminoperegrino.com/api/get-package-services/?id=${2}`) // URL de tu endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBasicIncludedServices(data.included_services);
        setBasicExtraServices(data.extra_services);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });

    fetch(`https://backend.caminoperegrino.com/api/get-package-services/?id=${1}`) // URL de tu endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setClassicIncludedServices(data.included_services);
        setClassicExtraServices(data.extra_services);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  return (
    <div className="services-info-modal-overlay" onClick={closeServicesModal}>
      <div className="services-info-modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="services-info-close-icon" onClick={closeServicesModal} aria-label="Cerrar">
          &times;
        </button>
        <h1 className="services-info-modal-title">{t("service.info.title")}</h1>
        <p className="services-info-modal-intro">
          {t("service.info.description.p1")} <strong>{t("service.info.description.p2")}</strong> {t("service.info.description.p3")}{" "}
          <strong>{t("service.info.description.p4")}</strong> {t("service.info.description.p5")}{" "}
          <strong>{t("service.info.description.p6")}</strong>
        </p>
        <div className="services-info-container">
          <ServicesInfoIncluded basicIncludedServices={basicIncludedServices} classicIncludedServices={classicIncludedServices} />
          <ServicesInfoExtra basicExtraServices={basicExtraServices} classicExtraServices={classicExtraServices} />
        </div>
      </div>
    </div>
  );
};

export default ServicesInfo;
