import React, { useEffect, useState } from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";
import { useBooking } from "../../../providers/BookProvider";
import { loadStripe } from "@stripe/stripe-js";
// import { useCookies } from "../../../providers/CookiesProvider";

const stripePromise = loadStripe("tu-clave-publica-de-stripe");

const LeftForm = () => {
  const { t } = useTranslation();
  // const { cookieConsent } = useCookies();
  const { formData, setFormData, setIsFormValid, selectedPackage } = useBooking();
  const [errors, setErrors] = useState({});
  const [prefix, setPrefix] = useState("+34"); // España por defecto
  const [phoneNumber, setPhoneNumber] = useState("");
  // const handleCheckout = async () => {
  //   if (cookieConsent !== "all") {
  //     alert("Debes aceptar las cookies para realizar un pago.");
  //     return;
  //   }

  //   const stripe = await stripePromise;

  //   // Crear la sesión de pago desde tu backend
  //   const response = await fetch("/api/create-checkout-session", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const session = await response.json();

  //   // Redirigir a Stripe Checkout
  //   const result = await stripe.redirectToCheckout({
  //     sessionId: session.id,
  //   });

  //   if (result.error) {
  //     console.error(result.error.message);
  //   }
  // };
  // Validación de campo individual
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value.trim()) error = t("validation.name.required");
        break;
      case "email":
        if (!/^\S+@\S+\.\S+$/.test(value)) error = t("validation.email.invalid");
        break;
      case "phone":
        if (!/^\+\d{1,3}\d{7,14}$/.test(value)) error = t("validation.phone.invalid");
        break;
      case "start_date":
        if (!value) {
          error = t("validation.date.required");
        } else {
          const selectedDate = new Date(value);
          const today = new Date();
          if (selectedDate < today) error = t("validation.date.past");
        }
        break;
      case "num_people":
        if (value <= 0) error = t("validation.people.invalid");
        break;
      case "extra_nights":
        if (value < 0) error = t("validation.nights.invalid");
        break;
      default:
        break;
    }
    return error;
  };

  // Validar un campo al salir del enfoque
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const countryPrefixes = [
    { code: "+34", country: "España" },
    { code: "+1", country: "EE.UU." },
    { code: "+44", country: "Reino Unido" },
    { code: "+49", country: "Alemania" },
    { code: "+33", country: "Francia" },
    // Agrega más países según lo necesites
  ];

  // Maneja el cambio de prefijo
  const handlePrefixChange = (e) => {
    setPrefix(e.target.value);
  };

  // Maneja el cambio en el número de teléfono
  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
    setFormData((prev) => ({ ...prev, phone: e.target.value }));
  };

  // Actualizar los datos del formulario
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Verificar si todo el formulario es válido
  useEffect(() => {
    const isValid =
      Object.values(formData).every((value) => value !== "" || (Array.isArray(value) && value.length >= 0)) &&
      Object.values(errors).every((error) => !error); // Asegúrate de que no haya errores
    setIsFormValid(isValid);
  }, [formData, errors]);
  return (
    <div className="book-form-left-contact-module">
      <div className="book-form-left-content-start">
        {/* Nombre */}
        <label className="book-form-left-label">
          {t("book.form.service.name")}
          <input
            className="book-form-left-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </label>

        {/* Email */}
        <label className="book-form-left-label">
          {t("book.form.service.email")}
          <input
            className="book-form-left-input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </label>

        {/* Teléfono */}
        <label className="book-form-left-label">
          {t("book.form.service.phone")}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <select id="prefix" value={prefix} onChange={handlePrefixChange} className="book-form-left-input-prefix">
              {countryPrefixes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.code} - {country.country} {/* Muestra el prefijo y el país en las opciones */}
                </option>
              ))}
            </select>
            <input
              type="tel"
              id="phone"
              value={phoneNumber}
              onChange={handlePhoneChange}
              onBlur={handleBlur}
              placeholder="Número de teléfono"
              className="book-form-left-input-extra-night"
            />
          </div>
          {errors.phone && <span className="error-message">{errors.phone}</span>}
        </label>
      </div>

      <div className="book-form-left-content-end">
        {/* Fecha de inicio */}
        <label className="book-form-left-label">
          {t("book.form.service.start.date")}
          <input
            type="date"
            name="start_date"
            className="book-form-left-input"
            value={formData.start_date || ""}
            onChange={handleInputChange}
            onBlur={handleBlur}
            min={new Date().toISOString().split("T")[0]} // No permite fechas pasadas
          />
          {errors.start_date && <span className="error-message">{errors.start_date}</span>}
        </label>

        {/* Número de personas */}
        <label className="book-form-left-label">
          {t("book.form.total.people")}
          <input
            className="book-form-left-input"
            type="number"
            name="num_people"
            value={formData.num_people}
            onChange={handleInputChange}
            onBlur={handleBlur}
            min="1"
          />
          {errors.num_people && <span className="error-message">{errors.num_people}</span>}
        </label>

        {/* Noches extra */}
        <label className="book-form-left-label">
          {t("book.form.nights.extra")}
          <div className="book-extra-night-content">
            <input
              className="book-form-left-input-extra-night"
              type="number"
              name="extra_nights"
              value={formData.extra_nights}
              onChange={handleInputChange}
              onBlur={handleBlur}
              min="0"
            />
            <div className="check-service-price">{`${selectedPackage.extra_night} €`}</div>
          </div>
          <div className="book-extra-night-sutitle">{t("book.form.nights.extra.subtitle")}</div>
          {errors.extra_nights && <span className="error-message">{errors.extra_nights}</span>}
        </label>
      </div>
    </div>
  );
};

export default LeftForm;
