import React from "react";
import "./layout.css";
import Header from "./header/Header";
import Home from "../home/Home";
import PackageModule from "../components/package/PackageModule";
import Routes from "../components/routes/Routes";
import Footer from "./footer/Footer";

const Layout = () => {
  return (
    <div className="container">
      <Header />
      <main className="content">
        <Home />
        <PackageModule />
        <Routes />
        <Footer />
      </main>
    </div>
  );
};

export default Layout;
