import React, { createContext, useState, useContext, useEffect } from "react";
import { handleAcceptCookies, handleDeclineCookies } from "../utils/functions";

// Crear el contexto
const CookiesContext = createContext();

// Hook personalizado para usar el contexto
export const useCookies = () => useContext(CookiesContext);

// Proveedor del contexto
export const CookiesProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(null);

  useEffect(() => {
    // Verificar si la cookie "cookies_consent" existe
    const consent = document.cookie.split("; ").find((row) => row.startsWith("cookies_consent="));
    if (consent) {
      setCookieConsent(consent.split("=")[1] === "true");
    }
  }, []);

  const acceptCookies = () => {
    handleAcceptCookies();
    setCookieConsent(true);
    document.cookie = "cookies_consent=true; path=/; max-age=31536000"; // Validez de 1 año
  };

  const declineCookies = () => {
    handleDeclineCookies();
    setCookieConsent(false);
    document.cookie = "cookies_consent=false; path=/; max-age=31536000"; // Validez de 1 año
  };

  return <CookiesContext.Provider value={{ cookieConsent, acceptCookies, declineCookies }}>{children}</CookiesContext.Provider>;
};
