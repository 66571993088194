import React from "react";
import "../BookForm.css";
import { getBookRoutePrice } from "../utils/functions";
import { useBooking } from "../../../providers/BookProvider";
import BookFormRightPrice from "./BookFormRightFieldPrice";
import BookFormRightFieldLong from "./BookFormRightFieldLong";
import { useTranslation } from "react-i18next";

const BookFormRightFromRoute = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, selectedRoute } = useBooking();
  const routeName = currentLang === "en" ? selectedRoute?.name_en : selectedRoute?.name;
  const packageName = currentLang === "en" ? selectedPackage?.name_en : selectedPackage?.name;
  return (
    <>
      <BookFormRightFieldLong label={"book.form.route"} value={routeName || ""} />
      <BookFormRightPrice
        label={"book.form.package"}
        name={packageName || ""}
        value={getBookRoutePrice(selectedPackage.id, selectedRoute)}
        isPackage={true}
      />
    </>
  );
};

export default BookFormRightFromRoute;
