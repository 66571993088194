import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
import { IoLogoTiktok } from "react-icons/io5";
import { SiGmail } from "react-icons/si";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/contactForm/ContactForm";

const Footer = () => {
  const { t } = useTranslation();
  const [isFormVisible, setIsFormVisible] = useState(false);

  const openForm = () => setIsFormVisible(true);
  const closeForm = () => setIsFormVisible(false);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-policies">
          <div className="footer-policies-title">{t("footer.policy.title")}</div>
          <div className="footer-policies-content">
            <Link className="footer-policies-text" to="/politica-de-cookies">
              {t("text.policy.cookies.title")}
            </Link>
          </div>
          <div className="footer-policies-content">
            <Link className="footer-policies-text" to="/politica-de-privacidad">
              {t("text.policy.privacy.title")}
            </Link>
          </div>
          <div className="footer-policies-content">
            <Link className="footer-policies-text" to="/politica-de-terminos-condiciones">
              {t("text.policy.terms.title")}
            </Link>
          </div>
          <div className="footer-policies-content">
            <Link className="footer-policies-text" to="/politica-de-cancelacion-reembolso">
              {t("text.policy.cancel.title")}
            </Link>
          </div>
        </div>
        <div className="footer-contact">
          <div className="footer-policies-title">{t("footer.contact.media.title")}</div>
          <div>
            <Link className="footer-policies-text" to="https://wa.me/34609902005">
              <div className="footer-contact-content">
                <FaWhatsapp className="footer-contact-icon" /> WhatsApp
              </div>
            </Link>
          </div>
          <div>
            <Link
              className="footer-policies-text"
              to="https://www.instagram.com/caminoperegrino_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
            >
              <div className="footer-contact-content">
                <FaInstagram className="footer-contact-icon" /> Instagram
              </div>
            </Link>
          </div>
          <div>
            <Link className="footer-policies-text" to="https://www.facebook.com/profile.php?id=61568243951839">
              <div className="footer-contact-content">
                <FaSquareFacebook className="footer-contact-icon" /> Facebook
              </div>
            </Link>
          </div>
          <div>
            <div className="footer-policies-text" onClick={() => openForm()}>
              <div className="footer-contact-content">
                <SiGmail className="footer-contact-icon" /> G-Mail
              </div>
            </div>
          </div>
          {isFormVisible && <ContactForm closeForm={closeForm} />}
        </div>
      </div>
      <p>© 2024 Camino de Santiago - {t("footer.rights.title")}</p>
    </footer>
  );
};

export default Footer;
