import React from "react";
import "./Package.css";
import { useBooking } from "../../providers/BookProvider";
import BookForm from "../book-form/BookForm";
import { getBookRoutePrice } from "../book-form/utils/functions";
import { useTranslation } from "react-i18next";

const Package = ({ pckg, isForm }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedPackage, setSelectedPackage, setFormData, isBookPackageModalOpen, openPackageForm, selectedRoute } = useBooking();

  const isSelected = selectedPackage?.id === pckg.id;

  const handleSelect = () => {
    setSelectedPackage(pckg);
    setFormData((prev) => ({ ...prev, package: pckg.id }));

    !isForm && openPackageForm();
  };
  const description = currentLang === "en" ? pckg.description_en : pckg.description;
  const pckgName = currentLang === "en" ? pckg.name_en : pckg.name;
  return (
    <div className={`${isForm ? "" : `package-content-image-bcgd ${pckg.id === 1 ? "classic" : "basic"}`}`}>
      <div className={`package-content ${isSelected && isForm ? "selected" : "image"}`}>
        <div className="package-title-container">
          <div className={`package-title ${isSelected && isForm}`}>{pckgName}</div>
          <div className="package-price">{`${t("package.price.from")} ${pckg.price} €`}</div>
        </div>
        {/* <img src={logo} alt="Logo GaliRoutes" className="package-image" /> */}
        <div className={`package-description ${isSelected && isForm}`}>{description}</div>
        <button className="package-button" onClick={handleSelect}>
          {t("route.modal.button.book")}
        </button>
        {isBookPackageModalOpen && <BookForm type={"package"} />}
      </div>
    </div>
  );
};

export default Package;
