export const getPrice = (pkg, extraServices, extraNights) => {
  const total = [pkg, extraServices, extraNights].reduce(
    (sum, value) => sum + (parseFloat(value) || 0), // Convierte a número y maneja `null` o `undefined`
    0
  );

  return `${total.toFixed(2)}`; // Redondea a 2 decimales
};

export const getBookRoutePrice = (packageId, selectedRoute) => {
  return packageId === 1 ? selectedRoute?.price_classic : selectedRoute?.price_basic;
};

export const getTotalPrice = (pkg, extraServices, extraNights, num_people) => {
  const total = getPrice(pkg, extraServices, extraNights) * (num_people || 1);
  return `${total.toFixed(2)}`;
};

export const getTotalStripePrice = (pkg, extraServices, extraNights, num_people) => {
  // Obtener el total base (sin multiplicar por la cantidad de personas)
  const total = getPrice(pkg, extraServices, extraNights) * (num_people || 1);

  // Convertir a centavos y devolver como un número entero
  return Math.round(total * 100); // Multiplicamos por 100 y redondeamos para centavos
};

export const getTotalDays = (initial, extra) => {
  return initial + Number(extra);
};

export const getTotalPriceExtraNights = (price, num_nights) => {
  const total = price * num_nights;

  return `${total.toFixed(2)}`;
};

export const calculateEndDate = (startDate, numDays) => {
  // Convierte la fecha de inicio a un objeto Date
  const start = new Date(startDate);

  // Sumar los días al objeto Date
  start.setDate(start.getDate() + numDays);

  // Formatear la fecha de fin en formato 'dd / mm / yyyy'
  const day = String(start.getDate()).padStart(2, "0") || "00";
  const month = String(start.getMonth() + 1).padStart(2, "0") || "00"; // Los meses empiezan desde 0
  const year = start.getFullYear() || "0000";

  // Devuelve la fecha formateada
  return `${day} / ${month} / ${year}`;
};

export const totalPrice = (formData) => {
  return formData.extraServices.reduce((sum, service) => {
    const price = parseFloat(service.price) || 0;
    const priceRounded = price.toFixed(2);

    return sum + parseFloat(priceRounded);
  }, 0);
};

// export const getPackageServices = async (packageId) => {
//   try {
//     const response = await api.get(`/packages/${packageId}/services/`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching package services:", error);
//     throw error;
//   }
// };

export const handleServiceSelection = (service, formData, setFormData) => {
  const updatedServices = [...formData.extraServices];

  const serviceIndex = updatedServices.findIndex((s) => s.name === service.name);

  if (serviceIndex !== -1) {
    updatedServices.splice(serviceIndex, 1);
  } else {
    updatedServices.push(service);
  }

  setFormData((prev) => ({
    ...prev,
    extraServices: updatedServices,
  }));
};

// Obtener fecha actual en formato yyyy-mm-dd
export const today = new Date().toISOString().split("T")[0];

export const validateForm = (formData, t, setErrors) => {
  const newErrors = {};

  // Validar nombre
  if (!formData.name || formData.name.trim() === "") {
    newErrors.name = t("book.form.errors.required");
  }

  // Validar email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!formData.email || !emailRegex.test(formData.email)) {
    newErrors.email = t("book.form.errors.invalidEmail");
  }

  // Validar teléfono
  const phoneRegex = /^\+\d{1,4}\d{7,15}$/; // +prefijo seguido de 7-15 dígitos
  if (!formData.phone || !phoneRegex.test(formData.phone)) {
    newErrors.phone = t("book.form.errors.invalidPhone");
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

export const handlePhoneChange = (e, setFormData) => {
  const value = e.target.value;
  // Añadir prefijo por defecto si no está presente
  if (!value.startsWith("+")) {
    setFormData((prev) => ({
      ...prev,
      phone: "+34" + value,
    }));
  } else {
    handleInputChange(e);
  }
};

export const onBookSubmit = async (userInfo, closeForm, showNotification, price, t, language) => {
  const bookPetitionUrl =
    language === "en"
      ? "https://backend.caminoperegrino.com/api/send-reservation-form-en/"
      : "https://backend.caminoperegrino.com/api/send-reservation-form/";
  try {
    const response = await fetch(bookPetitionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_name: userInfo.name,
        client_email: userInfo.email,
        client_number: userInfo.phone,
        package: userInfo.package,
        route: userInfo.route,
        start_date: userInfo.start_date,
        extra_nights: userInfo.extra_nights,
        total_people: userInfo.num_people,
        additional_service: userInfo.extraServices.map((service) => service.id), // Asegúrate de manejar el caso en el que no haya servicios adicionales
        total_price: parseInt(price),
        paid: false,
      }),
    });

    if (response.ok) {
      showNotification(`${t("book.form.success")}`, "success");
      closeForm(); // Cerrar el formulario al enviar
    } else {
      const errorData = await response.json();
      showNotification(`ERROR: ${errorData.error}`, "error");
    }
  } catch (error) {
    console.error("Error al enviar el formulario:", error);
    showNotification(`${t("book.form.eror")}`, "error");
  }
};

export const handleInputChange = (e, setCharCount) => {
  setCharCount(e.target.value.length);
};
