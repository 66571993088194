import React, { useState } from "react";
import "./ContactForm.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MAX_CHARS, MESSAGES, PATTERNS } from "./utils/constants";
import { handleInputChange, onSubmit } from "./utils/functions";
import { useNotification } from "../../providers/NotificationProvider";

const ContactForm = ({ closeForm }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { showNotification } = useNotification();
  const [charCount, setCharCount] = useState(0);

  return (
    <div className="modal-overlay" onClick={closeForm}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="contact-close-icon" onClick={closeForm} aria-label="Cerrar">
          &times;
        </button>
        <h2 className="title">{t("header.option.contact")}</h2>
        <form className="contact-form" onSubmit={handleSubmit((data) => onSubmit(data, closeForm, showNotification, t))}>
          <label className="contact-label" htmlFor="name">
            {t("contact.form.name")}
          </label>
          <input
            name="name"
            type="text"
            className={`contact-input ${errors.name ? "error" : ""}`}
            {...register("name", {
              required: t("contact.form.req"),
              pattern: {
                value: PATTERNS.name,
                message: t("contact.form.format.incorrect"),
              },
            })}
          />
          {errors.name && <p className="contact-msg">{errors.name.message}</p>}

          <label className="contact-label" htmlFor="mail">
            {t("contact.form.email")}
          </label>
          <input
            name="mail"
            type="text"
            className={`contact-input ${errors.mail ? "error" : ""}`}
            {...register("mail", {
              required: t("contact.form.req"),
              pattern: {
                value: PATTERNS.mail,
                message: t("contact.form.format.incorrect"),
              },
            })}
          />
          {errors.mail && <p className="contact-msg">{errors.mail.message}</p>}
          {/* NOT SURE ABOUT IF PHONE IS NEEDED */}
          {/* <label className="contact-label" htmlFor="phone">
            {t("contact.form.phone")}
          </label>
          <input
            name="phone"
            type="tel"
            placeholder="+34"
            className={`contact-input ${errors.phone ? "error" : ""}`}
            {...register("phone", {
              required: t("contact.form.req"),
              minLength: {
                value: 9,
                message: MESSAGES.phone,
              },
              maxLength: {
                value: 9,
                message: MESSAGES.phone,
              },
              pattern: {
                value: patterns.phone,
                message: MESSAGES.phone,
              },
            })}
          />
          {errors.phone && (
            <p className="contact-msg">{errors.phone.message}</p>
          )} */}

          <label className="contact-label" htmlFor="content">
            {t("contact.form.content")}
          </label>
          <textarea
            className={`contact-input content ${errors.content ? "error" : ""}`}
            {...register("content", { required: t("contact.form.req") })}
            name="content"
            maxLength={MAX_CHARS}
            onChange={(e) => handleInputChange(e, setCharCount)}
            type="text"
          />
          <div className="contact-content-counter">
            {charCount}/{MAX_CHARS}
          </div>
          {errors.content && <p className="contact-msg">{errors.content.message}</p>}

          <button type="submit" className="closeButton">
            {t("contact.form.button")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
